import type { PopoverContentProps } from 'tamagui';
import { Adapt, Popover, XStack, Stack, withStaticProperties, isWeb, YStack, Theme } from 'tamagui';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import { Text } from '../Text';
import type { ElevationProps } from '../Elevation';
import { Elevation } from '../Elevation';
import { useSafeAreaInsets } from '../../hooks';

export interface MenuProps {
  header?: React.ReactElement;
  items: {
    onPress: () => void;
    id: string;
    isSelected?: boolean;
    isVisible?: boolean;
    icon?: ReactElement;
    label: ReactElement | string;
    badge?: ReactElement;
  }[];
  children: React.ReactElement;
  elevation?: ElevationProps['level'];
  popoverProps?: PopoverContentProps;
  testID?: string;
}

// Not sure why this is needed but native doesn't trigger if the props aren't passed to the children
const TriggerWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Popover.Trigger asChild>
      <Stack asChild={!isWeb}>{children}</Stack>
    </Popover.Trigger>
  );
};

const MenuBase = ({ header, items, children, elevation = 1, popoverProps, testID }: MenuProps) => {
  const insets = useSafeAreaInsets();
  return (
    <Popover size="$xs" allowFlip placement="bottom">
      <TriggerWrapper>{children}</TriggerWrapper>

      <Popover.Adapt when="ltTablet">
        <Popover.Sheet
          modal
          dismissOnSnapToBottom
          snapPointsMode="fit"
          dismissOnOverlayPress
          portalProps={{
            marginTop: insets.top, // Hack to set a max height below insets in native
          }}
        >
          <Popover.Sheet.Overlay
            animation="100ms"
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
            opacity={0.5}
            backgroundColor="black"
            marginTop={-insets.top} // Hack to set a max height below insets in native
          />
          <Popover.Sheet.Frame backgroundColor="$background/surface">
            <Popover.Sheet.ScrollView>
              <Adapt.Contents />
            </Popover.Sheet.ScrollView>
          </Popover.Sheet.Frame>
        </Popover.Sheet>
      </Popover.Adapt>

      <Popover.Content
        disableFocusScope
        marginTop="$sm"
        padding={0}
        borderWidth={0}
        borderRadius="$surface/radius/surface-radius"
        enterStyle={{ y: -10, opacity: 0 }}
        exitStyle={{ y: -10, opacity: 0 }}
        elevate
        animation={[
          'quick',
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
        {...popoverProps}
      >
        <Elevation
          testID={testID}
          level={elevation}
          backgroundColor="$background/surface"
          overflow="hidden"
          $ltTablet={{
            maxWidth: '100%',
            borderRadius: 0,
          }}
          maxWidth={350}
          minWidth={300}
          borderRadius="$surface/radius/surface-radius"
        >
          {header ? (
            <XStack padding="$lg">
              <XStack
                $ltTablet={{ paddingHorizontal: '$sm', paddingTop: '$sm' }}
                justifyContent="space-between"
                flex={1}
              >
                {header}
              </XStack>
            </XStack>
          ) : null}
          <YStack $ltTablet={{ paddingHorizontal: '$lg', paddingBottom: !isWeb ? insets.bottom : '$lg' }}>
            {items.map((item, index) => {
              if (item.isVisible === false) {
                return null;
              }
              return (
                <React.Fragment key={item.id}>
                  <Theme inverse={item.isSelected}>
                    <Popover.Close asChild flexDirection="row">
                      <XStack
                        alignItems="center"
                        testID={item.id}
                        onPress={() => {
                          item.onPress();
                        }}
                        flexGrow={1}
                        cursor="pointer"
                        hoverStyle={{
                          backgroundColor: '$background/primary-hover',
                        }}
                        backgroundColor="$background/surface"
                        paddingVertical="$lg"
                        borderRadius="$md"
                        paddingHorizontal="$lg"
                        $ltTablet={{ paddingHorizontal: 0 }}
                      >
                        <XStack
                          gap="$space.sm"
                          justifyContent="space-between"
                          flex={1}
                          $ltTablet={{ paddingHorizontal: '$sm' }}
                        >
                          <XStack alignItems="center" gap="$space.sm" flex={1}>
                            <Stack flex={0}>{item.icon ? item.icon : null}</Stack>
                            {typeof item.label === 'string' ? <Text>{item.label}</Text> : item.label}
                          </XStack>
                          {item.badge ? item.badge : null}
                        </XStack>
                      </XStack>
                    </Popover.Close>
                  </Theme>
                  {items.length - 1 !== index && (
                    <Stack borderBottomColor="$border/app-subdued" borderBottomWidth={1} />
                  )}
                </React.Fragment>
              );
            })}
          </YStack>
        </Elevation>
      </Popover.Content>
    </Popover>
  );
};

export const Menu = withStaticProperties(MenuBase, {
  Close: Popover.Close,
});
