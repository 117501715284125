'use client';

import { styled, withStaticProperties } from '@tamagui/web';
import { XStack, YStack, Stack, ScrollView, type ScrollView as TamaguiScrollView } from 'tamagui';
import React, { useState, cloneElement, useRef, useEffect } from 'react';
import type { ReactElement } from 'react';
import { TextInput } from 'react-native';
import { Pencil } from '@cxnpl/ui/icons';
import { Text } from '../../Text';
import { IconButton } from '../../IconButton';

const ButtonTableBaseFrame = styled(XStack, {
  name: 'Button',
  tag: 'button',
  width: '100%',
  borderWidth: 1,
  paddingHorizontal: '$lg',
  paddingVertical: '$sm',
  minHeight: '$size.5xl',
  cursor: 'pointer',
  justifyContent: 'space-between',
  variants: {
    variant: {
      unselected: {
        backgroundColor: '$background/surface',
        borderColor: '$background/surface',
        borderRadius: '$surface/radius/surface-radius',
      },
      selected: {
        backgroundColor: '$background/surface-inverse',
        borderColor: '$background/surface-inverse',
        borderRadius: '$surface/radius/surface-radius',
      },
      hovered: {
        backgroundColor: '$background/primary-subdued',
        borderColor: '$background/primary-subdued',
        borderRadius: '$surface/radius/surface-radius',
      },
    },
  },
  defaultVariants: {
    variant: 'unselected',
  },
} as const);

const ButtonTableText = styled(Text, {
  name: 'ButtonTableText',
  variant: 'bodyMediumEm',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  variants: {
    variant: {
      unselected: {
        color: '$text/surface',
      },
      selected: {
        color: '$text/surface-inverse',
      },
      hovered: {
        color: '$text/surface',
      },
    },
  },
  defaultVariants: {
    variant: 'unselected',
  },
} as const);

const ButtonTableInput = styled(TextInput, {
  name: 'ButtonTableInput',
  fontSize: '$md',
  lineHeight: '$md',
  fontWeight: '$md',
  fontFamily: '$body',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  variants: {
    variant: {
      unselected: {
        color: '$text/surface',
      },
      selected: {
        color: '$text/surface-inverse',
      },
      hovered: {
        color: '$text/surface',
      },
    },
  },
  defaultVariants: {
    variant: 'unselected',
  },
} as const);

const ButtonTableLink = styled(Text, {
  name: 'ButtonTableLink',
  variant: 'linkSmallEm',
  textDecorationLine: 'underline',
  variants: {
    variant: {
      unselected: {
        color: '$text/primary',
      },
      selected: {
        color: '$background/primary-press',
      },
      hovered: {
        color: '$text/primary',
      },
    },
  },
  defaultVariants: {
    variant: 'unselected',
  },
} as const);

// Use variant instead of conditional rendering because otherwise the buttons move
function NavigationDivider({ variant }: { variant: 'default' | 'selected' }) {
  return (
    <XStack
      borderTopWidth="$size.navigation/size/nav-horizontal-divider-width"
      borderColor={variant === 'selected' ? '$background/surface' : '$border/surface-subdued'}
    />
  );
}

export const ButtonTableBase = withStaticProperties(ButtonTableBaseFrame, {
  Text: ButtonTableText,
  Input: ButtonTableInput,
  Link: ButtonTableLink,
  Divider: NavigationDivider,
});

export interface ButtonTableItemProps {
  id: string;
  isSelected: boolean;
  canEdit: boolean;
  label: string;
  icon?: ReactElement;
  badge?: ReactElement;
  onPress: () => void;
  onSave?: (newNickname: string) => Promise<void>;
  isVisible?: boolean;
}

export const ButtonTableItem = ({ isSelected, canEdit, label, icon, badge, onPress, onSave }: ButtonTableItemProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const [isSavingName, setIsSavingName] = useState<boolean>(false);
  const [newNickname, setNewNickname] = useState<string>('');

  let variant: 'selected' | 'unselected' | 'hovered';
  if (isHovered) {
    variant = 'hovered';
  } else if (isSelected) {
    variant = 'selected';
  } else {
    variant = 'unselected';
  }

  return (
    <ButtonTableBase
      flex={1}
      alignItems="center"
      variant={variant}
      onPress={() => {
        onPress();
        setIsHovered(false);
      }}
      onHoverIn={() => {
        setIsHovered(true);
      }}
      onHoverOut={() => {
        setIsHovered(false);
      }}
    >
      <XStack gap="$sm" height="100%" width="100%">
        <YStack justifyContent="center" alignItems="center" height="100%">
          {icon
            ? cloneElement(icon, {
                size: '$icon/size/sm',
                color: variant === 'selected' ? '$icon/surface-inverse' : '$icon/surface',
              })
            : null}
        </YStack>

        <XStack alignItems="center" gap="$sm" flex={1}>
          <Stack flex={1}>
            {isBeingEdited ? (
              <ButtonTableBase.Input
                // eslint-disable-next-line jsx-a11y/no-autofocus -- should auto focus as this appears only after user presses edit
                autoFocus
                variant={variant}
                value={newNickname}
                onChangeText={(input) => {
                  setNewNickname(input);
                }}
              />
            ) : (
              <ButtonTableBase.Text variant={variant}>{label}</ButtonTableBase.Text>
            )}
          </Stack>
          <XStack gap="$sm" alignItems="center" justifyContent="flex-end">
            {canEdit && (isBeingEdited || isSavingName) ? (
              <Stack>
                <ButtonTableBase.Link
                  disabled={isSavingName}
                  variant={variant}
                  onPress={() => {
                    if (isBeingEdited) {
                      setIsBeingEdited(false);
                      setIsSavingName(true);
                      if (onSave) {
                        void onSave(newNickname).finally(() => {
                          setIsSavingName(false);
                        });
                      }
                    } else {
                      setIsBeingEdited(true);
                      setNewNickname(label);
                    }
                  }}
                >
                  {/*  eslint-disable-next-line no-nested-ternary -- ignore */}
                  {isBeingEdited ? 'Save' : 'Saving...'}
                </ButtonTableBase.Link>
              </Stack>
            ) : null}
            {canEdit && !isBeingEdited && !isSavingName ? (
              <Stack>
                <IconButton
                  testID="changeLimitButton"
                  icon={<Pencil />}
                  variant="icon-only"
                  size="md"
                  onPress={() => {
                    setIsBeingEdited(true);
                    setNewNickname(label);
                  }}
                />
              </Stack>
            ) : null}
            <Stack>{badge}</Stack>
          </XStack>
        </XStack>
      </XStack>
    </ButtonTableBase>
  );
};

export interface ButtonTableProps {
  selectedItemId: string;
  items: Omit<ButtonTableItemProps, 'isSelected'>[];
  scrollOnMount?: boolean;
}

export const ButtonTable = ({ selectedItemId, items, scrollOnMount }: ButtonTableProps) => {
  const scrollViewRef = useRef<TamaguiScrollView>(null);
  const [selectedItemY, setSelectedItemY] = useState<number>(0);
  const hasScrolled = useRef<boolean>(false);

  useEffect(() => {
    if (!scrollOnMount || selectedItemY === 0 || hasScrolled.current) {
      return;
    }
    // Scroll to selected item, only on mount
    scrollViewRef.current?.scrollTo(selectedItemY);
    hasScrolled.current = true;
  }, [scrollOnMount, selectedItemY]);

  return (
    <ScrollView ref={scrollViewRef}>
      {items.map((item, index) => {
        if (item.isVisible === false) {
          return null;
        }
        return (
          <React.Fragment key={item.id}>
            <YStack
              onLayout={(e) => {
                if (item.id !== selectedItemId) {
                  return;
                }
                setSelectedItemY(e.nativeEvent.layout.y);
              }}
            >
              <ButtonTableItem
                id={item.id}
                isSelected={item.id === selectedItemId}
                canEdit={item.canEdit}
                label={item.label}
                icon={item.icon}
                badge={item.badge}
                onPress={item.onPress}
                onSave={item.onSave}
              />
            </YStack>
            {index !== items.length - 1 && (
              <ButtonTableBase.Divider
                variant={
                  // Handles all dividers in between
                  index !== items.length - 1 && item.id !== selectedItemId && items[index + 1]?.id !== selectedItemId
                    ? 'default'
                    : 'selected'
                }
              />
            )}
          </React.Fragment>
        );
      })}
    </ScrollView>
  );
};
