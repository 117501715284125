'use client';

/* eslint-disable no-lonely-if -- need to do ifs for something that needs good performance and can't return or default */
import type { ChangeEvent, ReactNode, Ref, ReactElement } from 'react';
import { useMemo, useRef, useState } from 'react';
import type { TamaguiElement, YStackProps, ScrollView as TamaguiScrollView } from 'tamagui';
import { Popover, YStack, ListItem, YGroup, Separator, ScrollView, getTokenValue, useWindowDimensions } from 'tamagui';
import type { NativeSyntheticEvent, TextInputKeyPressEventData, TextInput } from 'react-native';
import type { UseComboboxProps, UseComboboxStateChangeTypes } from 'downshift';
import { useCombobox } from 'downshift';
import { Search } from '@cxnpl/ui/icons';
import type { InputProps } from '../Input';
import { Input } from '../Input';
import { Text } from '../Text';

const MAX_RESULTS_CAP = 6;
const SEPARATOR_HEIGHT = 1;

export interface TypeaheadItemBaseProps {
  id: string;
  label: string;
  ref?: Ref<TamaguiElement>;
  highlighted?: boolean;
}

export interface TypeaheadItemRendererProps<T> extends TypeaheadItemBaseProps {
  item: T;
}

/**
 * Basic item to display in each slot of the typeahead. Use itemRenderer<T> for custom rendered items
 */
export function TypeaheadItem({ id, label, ref, highlighted }: TypeaheadItemBaseProps) {
  const TYPEAHEAD_ITEM_HEIGHT = getTokenValue('$size.5xl');
  return (
    <YStack
      id={id}
      focusable
      height={TYPEAHEAD_ITEM_HEIGHT}
      paddingHorizontal="$lg"
      paddingVertical="$lg"
      flex={1}
      justifyContent="center"
      backgroundColor={highlighted ? '$button/color/button-primary-bg-hover' : '$background/surface'}
      focusStyle={{
        outlineColor: 'transparent',
        outlineWidth: 0,
      }}
      ref={ref}
      cursor="pointer"
    >
      <Text
        variant="bodySmall"
        color={highlighted ? '$button/color/button-primary-fg' : '$text/surface'}
        pointerEvents="auto"
      >
        {label}
      </Text>
    </YStack>
  );
}

/**
 *
 */
export interface TypeaheadExtraItemContent {
  label: string;
  onPress?: () => void;
}

/**
 * Provides a wrapper to add an extra item at the bottom of the list
 * @param options - children: the content. onPress: function to capture user interaction. ref: used for accessible navigation
 * @returns
 */
export function TypeaheadExtraItem({
  children,
  onPress,
  onHoverIn,
  highlighted = false,
  ref,
  containerProps,
}: {
  children: ReactNode;
  onPress?: () => void;
  onHoverIn?: () => void;
  highlighted?: boolean;
  ref?: Ref<TamaguiElement>;
  containerProps?: YStackProps;
}) {
  const TYPEAHEAD_ITEM_HEIGHT = getTokenValue('$size.5xl');
  return (
    <YStack
      focusable
      height={TYPEAHEAD_ITEM_HEIGHT}
      paddingHorizontal="$lg"
      paddingVertical="$lg"
      flex={1}
      justifyContent="center"
      onHoverIn={() => {
        onHoverIn && onHoverIn();
      }}
      backgroundColor={highlighted ? '$button/color/button-primary-bg-hover' : '$background/surface'}
      focusStyle={{
        outlineColor: 'transparent',
        outlineWidth: 0,
      }}
      ref={ref}
      onPress={() => {
        onPress && onPress();
      }}
      {...containerProps}
      role="option"
      cursor="pointer"
    >
      {children}
    </YStack>
  );
}

/**
 * TypeaheadProps
 */
export interface TypeaheadProps<T> extends Omit<InputProps, 'onChangeText'> {
  /**
   * All items that could be listed. The displayed list will be items passed through `itemFilter`.
   */
  items: T[];
  /**
   * Transforms an item to a readable string. Used to display the list of items.
   * @param item - the generic item
   * @returns a string representing the item
   */
  itemToString: (item: T | null) => string;
  /**
   * Filters items to match the term searched
   * @param item - generic item
   * @param searchTerm - search term as string
   * @returns - boolean for filter matching
   */
  itemFilter: (item: T, searchTerm: string) => boolean;
  /**
   * The search term string
   */
  inputValue: string;
  /**
   * Function that runs when inputValue is changed
   * @param value - the changed inputValue
   * @returns
   */
  onInputValueChange: UseComboboxProps<T>['onInputValueChange'];
  /**
   * Callback when item is selected
   * @param item - the selected item
   * @returns
   */
  onSelectedItem?: (item: T) => Promise<void> | void;
  /**
   * Extra items added to the end of the list
   */
  extraItems?: TypeaheadExtraItemContent[];
  /**
   * Whether to un-focus the input when an item is selected
   * Defaults to true
   */
  unfocusOnItemSelect?: boolean;

  /**
   * Renderer override to get different types of typeaheads
   * @param props - item and base rendering props
   * @returns
   */
  itemRenderer?: (props: TypeaheadItemRendererProps<T>) => ReactElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- pass in any input ref
  inputRef: (instance: any) => void;
  /**
   * If input value has no match with existing items return this message
   */
  noItemsFoundMessage?: string;
}

export function Typeahead<T>(props: TypeaheadProps<T>) {
  const TYPEAHEAD_ITEM_HEIGHT = getTokenValue('$size.5xl');
  const {
    size = '$lg',
    items: allItems,
    itemFilter,
    itemToString,
    inputValue,
    onInputValueChange,
    onSelectedItem,
    id,
    testID,
    extraItems,
    unfocusOnItemSelect = true,
    itemRenderer,
    inputRef: errorFocusRef,
    noItemsFoundMessage,
    ...typeaheadProps
  } = props;

  const { height } = useWindowDimensions();
  const inputRef = useRef<TextInput | null>(null);
  const scrollViewRef = useRef<TamaguiScrollView>(null);
  const [popoverHeight, setPopoverHeight] = useState<number>(0);
  const [popoverWidth, setPopoverWidth] = useState<number>(0);
  // Get the max number of results that can be rendered, based on the height of the screen.
  // The number cannot be higher than MAX_RESULTS_CAP.
  const MAX_RESULTS = Math.min(
    MAX_RESULTS_CAP,
    // The smallest height available will be when the trigger element is in the middle of the screen, hence the division by 2
    Math.round(Math.floor((height - popoverHeight) / 2 - getTokenValue('$space.2xl')) / TYPEAHEAD_ITEM_HEIGHT)
  );
  const [extraItemHighlighted, setExtraItemHighlighted] = useState<number>(-1);
  const [scrollViewStart, setScrollViewStart] = useState<number>(0);
  const [scrollViewEnd, setScrollViewEnd] = useState<number>(
    MAX_RESULTS * TYPEAHEAD_ITEM_HEIGHT + (MAX_RESULTS - 1) * SEPARATOR_HEIGHT
  );

  const items = useMemo(
    () =>
      allItems.filter((item) => {
        return itemFilter(item, inputValue);
      }),
    [allItems, inputValue, itemFilter]
  );

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    getToggleButtonProps: _getToggleButtonProps,
    closeMenu,
    setHighlightedIndex,
    highlightedIndex,
    selectedItem,
  } = useCombobox({
    id,
    items,
    itemToString,
    inputValue,
    onInputValueChange: (changes) => {
      onInputValueChange?.(changes);
    },
    onStateChange: (changes) => {
      if (changes.type === ('__input_blur__' as UseComboboxStateChangeTypes)) {
        //When there is a closing blur state change that has no selected item but highlights index -1 and there is already a selectedItem
        //This means the same item was selected, so trigger onSelectedItem to fix the issue where this wasn't retriggered
        if (!changes.selectedItem && !changes.isOpen && changes.highlightedIndex === -1 && selectedItem) {
          void onSelectedItem?.(selectedItem);
        }
      }
    },
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        void onSelectedItem?.(changes.selectedItem);
      }
    },
    onIsOpenChange: () => {
      // Clear if extra Item is highlighted, mainly when user has Typeahead open and clicks again on the input field
      if (extraItemHighlighted >= 0) {
        setExtraItemHighlighted(-1);
      }
    },
  });

  const labelProps = getLabelProps({});

  const {
    onChange: onChangeDownshift,
    onChangeText: onChangeTextDownshift,
    onBlur: onBlurDownshift,
    ...inputProps
  } = getInputProps({}, { suppressRefError: true });

  const menuProps = getMenuProps({ role: 'combobox' }, { suppressRefError: true });

  /**
   * If the highlighted index via keyboard is above the current scroll viewport, scroll up
   * if the highlighted index via keyboard is below the current scroll, scroll down.
   * @param index - slot being highlighted
   */
  const scrollToIndex = (index: number) => {
    const scrollViewPortHeight = MAX_RESULTS * TYPEAHEAD_ITEM_HEIGHT + (MAX_RESULTS - 1) * SEPARATOR_HEIGHT;
    const start = index * TYPEAHEAD_ITEM_HEIGHT + index * SEPARATOR_HEIGHT;
    const end = start + TYPEAHEAD_ITEM_HEIGHT;

    if (end > scrollViewEnd) {
      scrollViewRef.current?.scrollTo(end - scrollViewPortHeight);
    } else if (start < scrollViewStart) {
      scrollViewRef.current?.scrollTo(start);
    }
  };

  const onInputKeyPress: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void = (e) => {
    // Fish for Enter being pressed, as if it's pressed when one of the extra items is highlighted, execute its onPress
    //Also note that if Enter is pressed, do not prevent default
    if (e.nativeEvent.key === 'Enter') {
      if (extraItemHighlighted >= 0 && extraItems && extraItems.length > 0) {
        extraItems[extraItemHighlighted]?.onPress?.();
        setExtraItemHighlighted(-1);
      }
    } else if (e.nativeEvent.key === 'ArrowDown' || e.nativeEvent.key === 'ArrowUp') {
      //Preventing default on Up and Down arrows, as the default action moves back to the beginning / end of the input being typed
      //That behaviour was kind of weird when all you want is navigate up and down
      e.preventDefault();
      const maxItems = items.length;
      const extraItemsLength = extraItems ? extraItems.length : 0;
      switch (e.nativeEvent.key) {
        case 'ArrowDown':
          if (extraItemHighlighted >= 0) {
            // Arrow is pressed down while one of the extraItems is already highlighted.
            // keep moving down until no more extraItems available
            if (extraItemHighlighted <= extraItemsLength - 2) {
              setExtraItemHighlighted(extraItemHighlighted + 1);
              //Adjust scroll only if there are too many items
              if (maxItems > MAX_RESULTS) {
                scrollToIndex(maxItems + extraItemHighlighted + 1);
              }
            }
          } else {
            if (highlightedIndex <= maxItems - 2) {
              setHighlightedIndex(highlightedIndex + 1);
              //Adjust scroll only if there are too many items
              if (maxItems > MAX_RESULTS) {
                scrollToIndex(highlightedIndex + 1);
              }
            } else {
              // After the last item, if there are extraItems, go to the first one
              if (extraItemsLength > 0) {
                setHighlightedIndex(-1);
                setExtraItemHighlighted(0);
                //Adjust scroll only if there are too many items
                if (maxItems > MAX_RESULTS) {
                  scrollToIndex(maxItems + 1);
                }
              }
            }
          }
          break;
        case 'ArrowUp':
          if (extraItemHighlighted >= 0) {
            // If an extraItem is highlighted, either go up one or jump back to regular items
            if (extraItemHighlighted === 0) {
              if (items.length > 0) {
                setExtraItemHighlighted(-1);
                setHighlightedIndex(items.length - 1);
                //Adjust scroll only if there are too many items
                if (maxItems > MAX_RESULTS) {
                  scrollToIndex(items.length - 1);
                }
              }
            } else {
              setExtraItemHighlighted(extraItemHighlighted - 1);
              //Adjust scroll only if there are too many items
              if (maxItems > MAX_RESULTS) {
                scrollToIndex(maxItems + extraItemHighlighted - 1);
              }
            }
          } else {
            if (highlightedIndex > 0) {
              setHighlightedIndex(highlightedIndex - 1);
              //Adjust scroll only if there are too many items
              if (maxItems > MAX_RESULTS) {
                scrollToIndex(highlightedIndex - 1);
              }
            }
          }
          break;
        default:
          break;
      }
    }
  };

  const onExtraItemHoverIn: (index: number) => void = (index) => {
    setHighlightedIndex(-1);
    setExtraItemHighlighted(index);
  };

  const onExtraItemHoverOut: () => void = () => {
    setExtraItemHighlighted(-1);
  };

  return (
    <Popover size="$lg" allowFlip placement="bottom" open={isOpen} hoverable disableFocus keepChildrenMounted>
      <Popover.Trigger asChild="web">
        <Input
          {...typeaheadProps}
          {...inputProps}
          ref={(e) => {
            errorFocusRef(e);
            inputRef.current = e;
          }}
          labelProps={{ ...labelProps }}
          testID={testID ? `${testID}-input` : undefined}
          onChange={(e) => {
            // NativeSyntheticEvent interface is wrong, it types target as number, but if you console.log it, it's an element
            onChangeDownshift?.(e as unknown as ChangeEvent);
            if (extraItemHighlighted > -1) {
              setExtraItemHighlighted(-1);
            }
          }}
          size={size}
          onChangeText={(e) => {
            onChangeTextDownshift?.(e as unknown as ChangeEvent);
            if (extraItemHighlighted > -1) {
              setExtraItemHighlighted(-1);
            }
          }}
          onLayout={(e) => {
            const layout = e.nativeEvent.layout;
            setPopoverWidth(layout.width);
            setPopoverHeight(layout.height);
            typeaheadProps.onLayout?.(e);
          }}
          onBlur={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- any
            onBlurDownshift?.(e as any);
            typeaheadProps.onBlur?.(e);
          }}
          startIcon={<Search />}
          onKeyPress={onInputKeyPress}
        />
      </Popover.Trigger>

      <Popover.Content
        width={popoverWidth}
        disableFocusScope
        trapFocus={false}
        focusable={false}
        borderWidth={0}
        borderRadius="$form/radius/formcontrol"
        backgroundColor="$background/surface"
        padding={0}
        overflow="hidden"
        marginVertical="$sm"
        enterStyle={{ y: -10, opacity: 0 }}
        exitStyle={{ y: -10, opacity: 0 }}
        elevate
        animation={[
          'quick',
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
        style={{
          WebkitBoxShadow: '0px 4px 8px 0px rgba(0,0,0,0.30)',
          MozBoxShadow: '0px 4px 8px 0px rgba(0,0,0,0.30)',
          boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.30)',
        }}
      >
        {isOpen ? (
          // TODO PWA-90 - Add list virtualization
          <ScrollView
            width="100%"
            showsVerticalScrollIndicator={false}
            focusable={false}
            testID={`${testID}-menu`}
            maxHeight={
              items.length > MAX_RESULTS
                ? MAX_RESULTS * TYPEAHEAD_ITEM_HEIGHT + (MAX_RESULTS - 1) * SEPARATOR_HEIGHT
                : items.length * TYPEAHEAD_ITEM_HEIGHT +
                  (items.length - 1) * SEPARATOR_HEIGHT +
                  (extraItems ? extraItems.length : 0) * TYPEAHEAD_ITEM_HEIGHT +
                  (extraItems ? extraItems.length - 1 : 0) * SEPARATOR_HEIGHT
            }
            ref={scrollViewRef}
            onScroll={(e) => {
              //Update the scroll viewport so keyboard navigation can know if it has to scroll
              setScrollViewStart(e.nativeEvent.contentOffset.y);
              setScrollViewEnd(
                e.nativeEvent.contentOffset.y +
                  MAX_RESULTS * TYPEAHEAD_ITEM_HEIGHT +
                  (MAX_RESULTS - 1) * SEPARATOR_HEIGHT
              );
            }}
            scrollEventThrottle={5}
          >
            <YGroup
              focusable={false}
              width="100%"
              separator={
                <Separator borderColor="$border/surface-subdued" borderWidth={0} borderTopWidth={SEPARATOR_HEIGHT} />
              }
              {...menuProps}
            >
              {noItemsFoundMessage && items.length === 0 ? (
                <YStack padding="$lg">
                  <Text variant="bodySmallEm" color="$text/surface">
                    {noItemsFoundMessage}
                  </Text>
                </YStack>
              ) : (
                items.map((item, index) => {
                  const labelId = `${index}-${itemToString(item)}`;
                  const { onPress, ...itemProps } = getItemProps({ item, index });
                  return (
                    <YGroup.Item key={`typeahead-label-${index}-${itemToString(item)}`}>
                      <ListItem
                        //size and fontSize defaulted, otherwise Tamagui tries to use $4 and it does not exist in our tokens
                        size="$lg"
                        fontSize="$lg"
                        padding={0}
                        {...itemProps}
                        aria-labelledby={labelId}
                        onPress={(e) => {
                          // @ts-expect-error -- runs the native event handler
                          onPress?.(e);
                          if (unfocusOnItemSelect) {
                            inputRef.current?.blur();
                          }
                        }}
                        testID={testID ? `${testID}-item-${index}` : undefined}
                        focusable={false}
                        onHoverIn={() => {
                          // On hover disable any extraItem highlighted
                          if (extraItemHighlighted >= 0) {
                            setExtraItemHighlighted(-1);
                          }
                        }}
                        onPointerMove={() => {
                          // When mouse is already over an item and the user presses up or down
                          // also have to disable any extra items
                          if (extraItemHighlighted >= 0) {
                            setExtraItemHighlighted(-1);
                          }
                        }}
                      >
                        {itemRenderer ? (
                          itemRenderer({
                            id: labelId,
                            label: `${itemToString(item)}`,
                            highlighted: highlightedIndex === index,
                            item,
                          })
                        ) : (
                          <TypeaheadItem
                            id={labelId}
                            label={`${itemToString(item)}`}
                            highlighted={highlightedIndex === index}
                          />
                        )}
                      </ListItem>
                    </YGroup.Item>
                  );
                })
              )}
              {extraItems?.map((extraItem, index) => {
                return (
                  <YGroup.Item key={`typeahead-extra-item-${index}`}>
                    <ListItem
                      //size and fontSize defaulted, otherwise Tamagui tries to use $4 and it does not exist in our tokens
                      size="$lg"
                      fontSize="$lg"
                      backgroundColor="$background/surface"
                      padding={0}
                      testID={testID ? `${testID}-item-${items.length + index}` : undefined}
                      focusable={false}
                      onPress={() => {
                        closeMenu();
                        if (unfocusOnItemSelect) {
                          inputRef.current?.blur();
                        }
                      }}
                      onHoverIn={() => {
                        onExtraItemHoverIn(index);
                      }}
                      onPointerMove={() => {
                        onExtraItemHoverIn(index);
                      }}
                      onHoverOut={() => {
                        onExtraItemHoverOut();
                      }}
                    >
                      <TypeaheadExtraItem
                        onPress={extraItem.onPress}
                        highlighted={extraItemHighlighted === index}
                        containerProps={{ 'aria-labelledby': `${id}-typeahead-extra-item-${index}` }}
                      >
                        <Text
                          variant="linkSmallEm"
                          color="$text/primary"
                          selectable={false}
                          id={`${id}-typeahead-extra-item-${index}`}
                        >
                          {extraItem.label}
                        </Text>
                      </TypeaheadExtraItem>
                    </ListItem>
                  </YGroup.Item>
                );
              })}
            </YGroup>
          </ScrollView>
        ) : null}
      </Popover.Content>
    </Popover>
  );
}
