'use client';

// Source: https://github.com/tamagui/tamagui/blob/master/packages/checkbox/src/Checkbox.tsx

// fork of radix
// https://github.com/radix-ui/primitives/tree/main/packages/react/checkbox/src/Checkbox.tsx

import type { GetProps, GetThemeValueForKey, TamaguiElement } from '@tamagui/core';
import {
  composeEventHandlers,
  createStyledContext,
  getTokenValue,
  isWeb,
  styled,
  useComposedRefs,
  useProps,
  useTheme,
  withStaticProperties,
} from '@tamagui/core';
import type { Scope } from 'tamagui';
import { createContextScope, useGetThemedIcon, useLabelContext, ThemeableStack, useControllableState } from 'tamagui';
import { registerFocusable } from '@tamagui/focusable';
import * as React from 'react';
import { useMemo, useRef, type ComponentProps } from 'react';
import { match } from 'ts-pattern';
import { shadowOpacity, shadowRadius } from '../../tokens/shadow';

export function usePrevious<T>(value: T) {
  const ref = useRef({ value, previous: value });

  // We compare values before making an update to ensure that
  // a change has been made. This ensures the previous value is
  // persisted correctly between renders.
  return useMemo(() => {
    if (ref.current.value !== value) {
      ref.current.previous = ref.current.value;
      ref.current.value = value;
    }
    return ref.current.previous;
  }, [value]);
}

export const CheckboxStyledContext = createStyledContext<{ size: number; scaleIcon: number }>({
  size: 18,
  scaleIcon: 1,
});

export type CheckedState = boolean | 'indeterminate';

export function isIndeterminate(checked?: CheckedState): checked is 'indeterminate' {
  return checked === 'indeterminate';
}

export function getState(checked: CheckedState) {
  // eslint-disable-next-line no-nested-ternary -- from Tamagui original implementation
  return isIndeterminate(checked) ? 'indeterminate' : checked ? 'checked' : 'unchecked';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- from Tamagui original implementation
type InputProps = any; //Radix.ComponentPropsWithoutRef<'input'>
interface BubbleInputProps extends Omit<InputProps, 'checked'> {
  checked: CheckedState;
  control: HTMLElement | null;
  bubbles: boolean;

  isHidden?: boolean;
}

export const BubbleInput = (props: BubbleInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- from Tamagui original implementation
  const { checked, bubbles = true, control, isHidden, ...inputProps } = props;
  const ref = React.useRef<HTMLInputElement>(null);
  const prevChecked = usePrevious(checked);
  //   const controlSize = useSize(control)

  // Bubble checked change to parents (e.g form change event)
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- from Tamagui original implementation
    const input = ref.current!;
    const inputProto = window.HTMLInputElement.prototype;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- from Tamagui original implementation
    const descriptor = Object.getOwnPropertyDescriptor(inputProto, 'checked')!;
    // eslint-disable-next-line @typescript-eslint/unbound-method -- from Tamagui original implementation
    const setChecked = descriptor.set;

    if (prevChecked !== checked && setChecked) {
      const event = new Event('click', { bubbles });
      input.indeterminate = isIndeterminate(checked);
      setChecked.call(input, isIndeterminate(checked) ? false : checked);
      input.dispatchEvent(event);
    }
  }, [prevChecked, checked, bubbles]);

  return (
    <input
      type="checkbox"
      defaultChecked={isIndeterminate(checked) ? false : checked}
      {...inputProps}
      tabIndex={-1}
      ref={ref}
      aria-hidden={isHidden}
      style={{
        ...(isHidden
          ? {
              // ...controlSize,
              position: 'absolute',
              pointerEvents: 'none',
              opacity: 0,
              margin: 0,
            }
          : {
              appearance: 'auto',
              accentColor: 'var(--color6)',
            }),

        ...props.style,
      }}
    />
  );
};

/* -------------------------------------------------------------------------------------------------
 * CheckboxIndicator
 * -----------------------------------------------------------------------------------------------*/

const INDICATOR_NAME = 'CheckboxIndicator';

const CheckboxIndicatorFrame = styled(ThemeableStack, {
  // use Checkbox for easier themes
  name: INDICATOR_NAME,
  context: CheckboxStyledContext,
});

type CheckboxIndicatorFrameProps = GetProps<typeof CheckboxIndicatorFrame>;

export type CheckboxIndicatorProps = CheckboxIndicatorFrameProps & {
  /**
   * Used to force mounting when more control is needed. Useful when
   * controlling animation with React animation libraries.
   */
  forceMount?: true;
  /**
   * Used to disable passing styles down to children.
   */
  disablePassStyles?: boolean;
};

const CheckboxIndicator = CheckboxIndicatorFrame.extractable(
  // eslint-disable-next-line react/display-name -- from original Tamagui implementation
  React.forwardRef<TamaguiElement, CheckboxIndicatorProps>(
    (props: ScopedProps<CheckboxIndicatorProps>, forwardedRef) => {
      const { __scopeCheckbox, children: childrenProp, forceMount, disablePassStyles, ...indicatorProps } = props;
      if (process.env.NODE_ENV === 'development' && !childrenProp) {
        // eslint-disable-next-line no-console -- from the original Tamagui implementation
        console.warn(
          `Warning: You created a Checkbox.Indicator without passing an child prop for it to use as an icon.`
        );
      }
      const context = useCheckboxContext(INDICATOR_NAME, __scopeCheckbox);
      const styledContext = React.useContext<{ size: number; scaleIcon: number }>(CheckboxStyledContext);

      // Use 3/4 of the height for the icon
      const iconSize = styledContext.size * 0.75;

      const theme = useTheme();
      const getThemedIcon = useGetThemedIcon({ size: iconSize, color: theme.color });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- from Tamagui original implementation
      const childrens = React.Children.toArray(childrenProp);
      const children = childrens.map((child) => {
        if (disablePassStyles || !React.isValidElement(child)) {
          return child;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- from Tamagui original implementation
        return getThemedIcon(child);
      });

      if (forceMount || isIndeterminate(context.state) || context.state) {
        return (
          <CheckboxIndicatorFrame
            data-state={getState(context.state)}
            data-disabled={context.disabled ? '' : undefined}
            pointerEvents="none"
            {...indicatorProps}
            ref={forwardedRef}
          >
            {children}
          </CheckboxIndicatorFrame>
        );
      }

      return null;
    }
  )
);

CheckboxIndicator.displayName = INDICATOR_NAME;

/* -------------------------------------------------------------------------------------------------
 * Checkbox
 * -----------------------------------------------------------------------------------------------*/

const CHECKBOX_NAME = 'Checkbox';

export const CheckboxFrame = styled(ThemeableStack, {
  name: CHECKBOX_NAME,
  tag: 'button',
  borderRadius: '$checkbox/radius/checkbox-radius',
  context: CheckboxStyledContext,
  backgroundColor: '$background/transparent',
  alignItems: 'center',
  justifyContent: 'center',
  pressTheme: true,
  focusable: true,
  borderWidth: 1,
  borderColor: '$form/color/form-border-default',

  variants: {
    unstyled: {},

    disabled: {
      true: {
        pointerEvents: 'none',
        userSelect: 'none',
        cursor: 'not-allowed',
        backgroundColor: '$background/disabled',
      },
    },

    size: {
      md: {},
      lg: {},
    },
  } as const,

  defaultVariants: {},
});

type ScopedProps<P> = P & { __scopeCheckbox?: Scope };
const [createCheckboxContext, createCheckboxScope] = createContextScope(CHECKBOX_NAME);

interface CheckboxContextValue {
  state: CheckedState;
  disabled?: boolean;
}

const [CheckboxProvider, useCheckboxContext] = createCheckboxContext<CheckboxContextValue>(CHECKBOX_NAME);

type CheckboxFrameProps = GetProps<typeof CheckboxFrame>;

interface CheckboxExtraProps {
  checked?: CheckedState;
  defaultChecked?: CheckedState;
  required?: boolean;
  /**
   *
   * @param onCheckedChange -- checked Either boolean or "indeterminate" which is meant to allow for a third state that means "neither", usually indicated by a minus sign.
   */
  onCheckedChange?: (checked: CheckedState) => void;
  labelledBy?: string;
  name?: string;
  value?: string;
  native?: boolean;
  size?: 'md' | 'lg';
}

export type CheckboxPropsInternal = Omit<CheckboxFrameProps, 'checked' | 'defaultChecked'> & CheckboxExtraProps;

const CheckboxComponent = CheckboxFrame.styleable<CheckboxExtraProps>(function Checkbox(
  props: ScopedProps<CheckboxPropsInternal>,
  forwardedRef
) {
  const {
    __scopeCheckbox,
    labelledBy: ariaLabelledby,
    name,
    checked: checkedProp,
    defaultChecked,
    required,
    disabled,
    value = 'on',
    onCheckedChange,
    native,
    size,
    ...checkboxProps
  } = props;
  const [button, setButton] = React.useState<HTMLButtonElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- from original Tamagui implementation
  const composedRefs = useComposedRefs(forwardedRef, (node) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- from Tamagui original implementation
    setButton(node as any);
  });
  const hasConsumerStoppedPropagationRef = React.useRef(false);
  const propsActive = useProps(props);
  // We set this to true by default so that events bubble to forms without JS (SSR)
  // eslint-disable-next-line no-nested-ternary -- from Tamagui original implementation
  const isFormControl = isWeb ? (button ? Boolean(button.closest('form')) : true) : false;
  const [checked = false, setChecked] = useControllableState({
    prop: checkedProp,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- from Tamagui original implementation
    defaultProp: defaultChecked!,
    onChange: onCheckedChange,
  });

  const pxSize = getTokenValue(size === 'lg' ? '$lg' : '$sm', 'size');

  const labelId = useLabelContext(button);
  const labelledBy = ariaLabelledby || labelId;

  if (process.env.TAMAGUI_TARGET === 'native') {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- disabled by Tamagui
    React.useEffect(() => {
      if (!props.id) {
        return;
      }
      if (disabled) {
        return;
      }

      return registerFocusable(props.id, {
        focusAndSelect: () => {
          setChecked((x) => !x);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function -- from original Tamagui implementation
        focus: () => {},
      });
    }, [props.id, setChecked, disabled]);
  }

  return (
    <CheckboxProvider scope={__scopeCheckbox} state={checked} disabled={disabled}>
      {isWeb && native ? (
        <BubbleInput
          control={button}
          bubbles={!hasConsumerStoppedPropagationRef.current}
          name={name}
          value={value}
          checked={checked}
          required={required}
          disabled={disabled}
          id={props.id}
        />
      ) : (
        <>
          <CheckboxFrame
            width={pxSize}
            height={pxSize}
            tag="button"
            role="checkbox"
            aria-labelledby={labelledBy}
            aria-checked={isIndeterminate(checked) ? 'mixed' : checked}
            aria-required={required}
            data-state={getState(checked)}
            data-disabled={disabled ? '' : undefined}
            disabled={disabled}
            hoverStyle={{
              borderColor: '$form/color/form-border-selected',
              shadowColor: !disabled ? '$form/color/form-border-selected' : undefined,
              shadowOpacity: shadowOpacity.on,
              shadowRadius: shadowRadius.on,
            }}
            backgroundColor={
              match({ checked, disabled })
                .with({ disabled: true }, () => '$background/disabled')
                .with({ checked: true }, () => '$checkbox/color/checkbox-fg-default')
                .otherwise(() => '$background/transparent') as GetThemeValueForKey<'backgroundColor'>
            }
            borderColor={
              match({ checked, disabled, required })
                .with({ disabled: true }, () => '$border/disabled')
                .with({ required: true }, () => '$checkbox/color/checkbox-border-danger')
                .with({ checked: true }, () => '$checkbox/color/checkbox-fg-selected')
                .otherwise(() => '$checkbox/color/checkbox-border-default') as GetThemeValueForKey<'backgroundColor'>
            }
            {...checkboxProps}
            ref={composedRefs}
            {...(isWeb && {
              type: 'button',
              value,
              onKeyDown: composeEventHandlers((props as React.HTMLProps<HTMLButtonElement>).onKeyDown, (event) => {
                // According to WAI ARIA, Checkboxes don't activate on enter keypress
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }),
            })}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- from Tamagui original implementation
            onPress={composeEventHandlers(props.onPress as any, (event) => {
              setChecked((prevChecked) => (isIndeterminate(prevChecked) ? true : !prevChecked));
              if (isFormControl) {
                hasConsumerStoppedPropagationRef.current = event.isPropagationStopped();
                // if checkbox is in a form, stop propagation from the button so that we only propagate
                // one click event (from the input). We propagate changes from an input so that native
                // form validation works and form events reflect checkbox updates.
                if (!hasConsumerStoppedPropagationRef.current) {
                  event.stopPropagation();
                }
              }
            })}
          >
            <CheckboxStyledContext.Provider size={pxSize} scaleIcon={1}>
              {propsActive.children}
            </CheckboxStyledContext.Provider>
          </CheckboxFrame>

          {isWeb && isFormControl ? (
            <BubbleInput
              isHidden
              control={button}
              bubbles={!hasConsumerStoppedPropagationRef.current}
              name={name}
              value={value}
              checked={checked}
              required={required}
              disabled={disabled}
            />
          ) : null}
        </>
      )}
    </CheckboxProvider>
  );
});

/**
 * Public Props API for Checkbox Component.
 *
 * A minimal set of props to render and control the Checkbox component. Extend this interface only if needed new props.
 */
export type CheckboxProps = Pick<
  ComponentProps<typeof CheckboxComponent>,
  'children' | 'ref' | 'id' | 'testID' | 'disabled' | 'checked' | 'onCheckedChange' | 'defaultChecked' | 'size'
>;

const CheckboxPublic = (props: CheckboxProps) => {
  return <CheckboxComponent {...props} />;
};

// Tamagui specific static props
export const Checkbox = withStaticProperties(CheckboxPublic, {
  Indicator: CheckboxIndicator,
  Props: CheckboxStyledContext.Provider,
});

export { createCheckboxScope };
