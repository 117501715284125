import type { ReactElement } from 'react';
import { cloneElement } from 'react';
import { YStack, XStack } from 'tamagui';
import { Text } from '../Text';

export type TimelineDataStructure = {
  icon: ReactElement;
  title: string;
  text: string;
}[];

export interface TimelineProps {
  timeline: TimelineDataStructure;
}

/**
 * Take in an array of timeline objects
 * Each will render an icon, title and text
 * The order of the array will render from the top down
 */
const Timeline = ({ timeline }: TimelineProps) => {
  return (
    <YStack gap="$lg" display="unset">
      {timeline.map((element, index) => {
        return (
          <XStack gap="$sm" key={`${element.title}-${index}`} width="100%">
            <YStack justifyContent="flex-start" alignItems="center" width={20}>
              {cloneElement(element.icon, {
                color: '$feature/color/feature-icon-fg',
                backgroundColor: '$background/transparent',
              })}

              <YStack
                backgroundColor={index < timeline.length - 1 ? '$border/app' : 'transparent'}
                width={1}
                flex={1}
                marginVertical="$xs"
              />
            </YStack>

            <YStack paddingBottom="$lg" gap="$xs" flex={1}>
              <Text variant="bodySmallEm">{element.title}</Text>
              <XStack flex={1}>
                <Text variant="bodySmall" color="$foreground/surface-subdued">
                  {element.text}
                </Text>
              </XStack>
            </YStack>
          </XStack>
        );
      })}
    </YStack>
  );
};

export { Timeline };
