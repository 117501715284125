import { semanticTokens as cxnplSemanticTokens, componentTokens as cxnplComponentTokens } from './cxnpl';
import { semanticTokens as gsbSemanticTokens, componentTokens as gsbComponentTokens } from './gsb';
import { semanticTokens as myobSemanticTokens, componentTokens as myobComponentTokens } from './myob';

type SemanticTokensColor = typeof cxnplSemanticTokens.semanticTokensColor;
type SemanticTokensRadius = typeof cxnplSemanticTokens.semanticTokensRadius;
type SemanticTokensSize = typeof cxnplSemanticTokens.semanticTokensSize;
type SemanticTokensSpace = typeof cxnplSemanticTokens.semanticTokensSpace;

type ComponentTokensColor = typeof cxnplComponentTokens.componentTokensColor;
type ComponentTokensSpace = typeof cxnplComponentTokens.componentTokensSpace;
type ComponentTokensSize = typeof cxnplComponentTokens.componentTokensSize;
type ComponentTokensRadius = typeof cxnplComponentTokens.componentTokensRadius;

const semanticTokens: {
  semanticTokensColor: SemanticTokensColor;
  semanticTokensRadius: SemanticTokensRadius;
  semanticTokensSize: SemanticTokensSize;
  semanticTokensSpace: SemanticTokensSpace;
} = {
  // @ts-expect-error -- we will assign the values later
  semanticTokensColor: {},
  // @ts-expect-error -- we will assign the values later
  semanticTokensSpace: {},
  // @ts-expect-error -- we will assign the values later
  semanticTokensSize: {},
  // @ts-expect-error -- we will assign the values later
  semanticTokensRadius: {},
};

const componentTokens: {
  componentTokensColor: ComponentTokensColor;
  componentTokensSpace: ComponentTokensSpace;
  componentTokensSize: ComponentTokensSize;
  componentTokensRadius: ComponentTokensRadius;
} = {
  // @ts-expect-error -- we will assign the values later
  componentTokensColor: {},
  // @ts-expect-error -- we will assign the values later
  componentTokensSpace: {},
  // @ts-expect-error -- we will assign the values later
  componentTokensSize: {},
  // @ts-expect-error -- we will assign the values later
  componentTokensRadius: {},
};

const brandName = process.env.BRAND_NAME;

// Use if/else instead of the statement switch to tree-shake the unused code branches
if (brandName === 'gsb') {
  Object.assign(semanticTokens, gsbSemanticTokens);
  Object.assign(componentTokens, gsbComponentTokens);
} else if (brandName === 'myob') {
  Object.assign(semanticTokens, myobSemanticTokens);
  Object.assign(componentTokens, myobComponentTokens);
} else {
  Object.assign(semanticTokens, cxnplSemanticTokens);
  Object.assign(componentTokens, cxnplComponentTokens);
}

const { semanticTokensSize, semanticTokensSpace, semanticTokensRadius, semanticTokensColor } = semanticTokens;
const { componentTokensSize, componentTokensSpace, componentTokensRadius, componentTokensColor } = componentTokens;

export {
  componentTokensColor,
  componentTokensRadius,
  componentTokensSize,
  componentTokensSpace,
  semanticTokensColor,
  semanticTokensRadius,
  semanticTokensSize,
  semanticTokensSpace,
};
