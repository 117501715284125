'use client';

import { Switch as TamaguiSwitch } from 'tamagui';
import { styled, withStaticProperties, getTokenValue } from '@tamagui/web';
import { forwardRef, useId } from 'react';

export interface SwitchProps {
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  testID?: string;
  checked?: boolean;
  onPress?: () => Promise<void> | void;
}

type TamaguiButtonElement = HTMLButtonElement;

export type SwitchElement = TamaguiButtonElement;

export const SwitchFrame = styled(TamaguiSwitch, {
  style: { transition: 'all 0.25s' },
  height: getTokenValue('$switch/size/sm'),
  alignItems: 'center',
  justifyContent: 'center',
  padding: 1,
  minHeight: 'auto',
  borderWidth: 1,
  outlineWidth: 0,
  borderRadius: '$radius.round',
  cursor: 'pointer',
  variants: {
    checked: {
      true: {
        backgroundColor: getTokenValue('$switch/color/switch-bg-selected'),
        borderColor: getTokenValue('$switch/color/switch-border-selected'),
      },
      false: {
        backgroundColor: getTokenValue('$switch/color/switch-bg-default'),
        borderColor: getTokenValue('$switch/color/switch-border-default'),
      },
    },
    unstyled: {},
    disabled: {
      true: {
        backgroundColor: '$background/disabled',
        borderColor: '$border/disabled',
        cursor: 'auto',
      },
    },
    dimension: {
      sm: {
        height: getTokenValue('$switch/size/sm'),
        width: getTokenValue('$switch/size/sm') - getTokenValue('$xxs') + getTokenValue('$xl'),
      },
      md: {
        height: getTokenValue('$switch/size/md'),
        width: getTokenValue('$switch/size/md') - getTokenValue('$xxs') + getTokenValue('$2xl'),
      },
      lg: {
        height: getTokenValue('$switch/size/lg'),
        width: getTokenValue('$switch/size/lg') - getTokenValue('$xxs') + getTokenValue('$2xl'),
      },
    },
  } as const,
});

export const SwitchThumb = styled(TamaguiSwitch.Thumb, {
  borderRadius: 1000,
  position: 'absolute',
  style: { transition: 'all 0.25s' },
  variants: {
    checked: {
      true: {
        backgroundColor: getTokenValue('$switch/color/switch-icon-selected'),
      },
      false: {
        backgroundColor: getTokenValue('$switch/color/switch-icon-default'),
      },
    },
    disabled: {
      true: {
        backgroundColor: '$foreground/disabled',
      },
    },
    dimension: {
      sm: {
        width: getTokenValue('$switch/size/sm') - 2 * getTokenValue('$xxs'),
        height: getTokenValue('$switch/size/sm') - 2 * getTokenValue('$xxs'),
      },
      md: {
        width: getTokenValue('$switch/size/md') - 2 * getTokenValue('$xxs'),
        height: getTokenValue('$switch/size/md') - 2 * getTokenValue('$xxs'),
      },
      lg: {
        width: getTokenValue('$switch/size/lg') - 2 * getTokenValue('$xxs'),
        height: getTokenValue('$switch/size/lg') - 2 * getTokenValue('$xxs'),
      },
    },
  } as const,
});

const SwitchInternal = forwardRef<SwitchElement, SwitchProps>(function Switch(props, forwardedRef) {
  const { testID, size = 'sm', disabled, onPress, checked, ...switchProps } = props;

  return (
    <SwitchFrame
      testID={testID}
      checked={checked}
      aria-labelledby={useId()}
      aria-checked={checked}
      data-state={checked ? 'on' : 'off'}
      data-disabled={disabled ? '' : undefined}
      disabled={disabled}
      {...switchProps}
      ref={forwardedRef}
      onPress={() => {
        void onPress?.();
      }}
      dimension={size}
      unstyled
    >
      <SwitchThumb dimension={size} checked={checked} disabled={disabled} />
    </SwitchFrame>
  );
});

export const Switch = withStaticProperties(SwitchInternal, {
  Thumb: SwitchThumb,
});
