import { createUniqueFieldSchema } from '@ts-react/form';
import { z } from 'zod';
import type {
  AddressFieldSchema,
  BusinessLookupFieldSchema,
  IndustryFieldSchema,
  OccupationFieldSchema,
  AmountFrequencySchema,
  InstitutionFieldSchema,
  PartialAmountFrequencySchema,
} from './fields';
import {
  BooleanCheckboxField,
  CurrencyField,
  DateField,
  NumberField,
  PasswordCodeField,
  PasswordField,
  SelectField,
  SwitchField,
  TextField,
  TextAreaField,
  ToggleGroupBooleanField,
  ToggleGroupMultiField,
  ToggleGroupSingleField,
  createBusinessLookupFieldItemSchema,
  BusinessLookupField,
  IndustryField,
  PayeesField,
  FileUploadField,
  MultiCheckboxField,
  createIndustryFieldItemSchema,
  createAddressFieldItemSchema,
  AddressField,
  createOccupationFieldItemSchema,
  OccupationField,
  FileUploadFieldSchema,
  SelectRadioField,
  createAustralianPhoneNumberSchema,
  AustralianPhoneNumberField,
  MultiCheckboxRoleField,
  AmountFrequencyField,
  createAmountFrequencySchema,
  createInstitutionFieldItemSchema,
  InstitutionField,
} from './fields';

/**
 * Fields to use within zod form schema
 */
export const FormField = {
  /**
   * Text field
   */
  Text: (props?: Parameters<typeof z.string>[0]) => z.string(props),
  /**
   * Text Area field
   */
  TextArea: (
    init: (zString: typeof z.string) => z.ZodString = (zString) => zString() // Expose z.string to enable user to chain fns, .min(), .regex(), etc
  ) => createUniqueFieldSchema(init(z.string), 'textArea'), // Wrap result with createUniqueFieldSchema for mapping to work
  /**
   * Number field
   */
  Number: (props?: Parameters<typeof z.number>[0]) =>
    z.number({
      invalid_type_error: props?.required_error || 'Required',
      ...props,
    }),
  /**
   * Password field
   */
  Password: (
    init: (zString: typeof z.string) => z.ZodString = (zString) => zString() // Expose z.string to enable user to chain fns, .min(), .regex(), etc
  ) => createUniqueFieldSchema(init(z.string), 'password'), // Wrap result with createUniqueFieldSchema for mapping to work
  /**
   * Individual character code field
   *
   * You can optionally pass in `secureTextEntry` to `SchemaForm` props to hide values
   */
  PasswordCode: (
    init: (zString: typeof z.string) => z.ZodString = (zString) => zString() // Expose z.string to enable user to chain fns, .min(), .regex(), etc
  ) => createUniqueFieldSchema(init(z.string), 'passwordCode'), // Wrap result with createUniqueFieldSchema for mapping to work
  /**
   * Boolean checkbox field
   */
  BooleanCheckbox: (props?: Parameters<typeof z.boolean>[0]) =>
    createUniqueFieldSchema(z.boolean(props), 'booleanCheckbox'), // Wrap result with createUniqueFieldSchema for mapping to work
  /**
   * Currency field
   */
  Currency: (init: (zNumber: typeof z.number) => z.ZodNumber | z.ZodOptional<z.ZodNumber> = (zNumber) => zNumber()) =>
    createUniqueFieldSchema(init(z.number), 'currency'),
  /**
   * Select field
   */
  Select: (init: (zString: typeof z.string) => z.ZodString | z.ZodOptional<z.ZodString> = (zString) => zString()) =>
    createUniqueFieldSchema(init(z.string), 'select'),
  /**
   * Select radio field
   */
  SelectRadio: (
    init: (zString: typeof z.string) => z.ZodString | z.ZodOptional<z.ZodString> = (zString) => zString()
  ) => createUniqueFieldSchema(init(z.string), 'selectRadio'),
  /**
   * Switch field
   */
  Switch: (props?: Parameters<typeof z.boolean>[0]) => createUniqueFieldSchema(z.boolean(props), 'switch'), // Wrap result with createUniqueFieldSchema for mapping to work
  /**
   * Toggle group - boolean
   */
  ToggleGroupBoolean: (props?: Parameters<typeof z.boolean>[0]) =>
    createUniqueFieldSchema(z.boolean(props), 'toggleGroupBoolean'),
  /**
   * Toggle group field - single select
   */
  ToggleGroupSingle: (
    init: (zString: typeof z.string) => z.ZodString | z.ZodOptional<z.ZodString> = (zString) => zString()
  ) => createUniqueFieldSchema(init(z.string), 'toggleGroupSingle'),
  /**
   * Toggle group field - multi select
   */
  ToggleGroupMulti: (init: (zString: typeof z.string) => z.ZodString = (zString) => zString()) =>
    createUniqueFieldSchema(init(z.string).array(), 'toggleGroupMulti'),
  /**
   * Date field
   */
  Date: (init: (zDate: typeof z.string) => z.ZodString | z.ZodOptional<z.ZodString> = (zString) => zString()) =>
    createUniqueFieldSchema(init(z.string), 'date'),
  /**
   * File field
   */
  File: () => createUniqueFieldSchema(FileUploadFieldSchema, 'file'),
  /**
   * MultiCheckbox Field
   */
  MultiCheckbox: (init: (zString: typeof z.string) => z.ZodString = (zString) => zString()) =>
    createUniqueFieldSchema(init(z.string).array(), 'multiCheckbox'),

  /**
   * Phone number field
   */
  AustralianPhoneNumber: (props?: Parameters<typeof createAustralianPhoneNumberSchema>[1]) =>
    createUniqueFieldSchema(createAustralianPhoneNumberSchema('PHONE', props), 'auPhoneNumber'), // Wrap result with createUniqueFieldSchema for mapping to work

  /**
   * MultiCheckoxRole Field
   */

  MultiCheckboxRoleField: (init: (zString: typeof z.string) => z.ZodString = (zString) => zString()) =>
    createUniqueFieldSchema(init(z.string).array(), 'multiCheckboxRole'),

  /**
   * Mobile number field
   */
  AustralianMobileNumber: (props?: Parameters<typeof createAustralianPhoneNumberSchema>[1]) =>
    createUniqueFieldSchema(createAustralianPhoneNumberSchema('MOBILE', props), 'auMobileNumber'), // Wrap result with createUniqueFieldSchema for mapping to work

  AmountFrequencyField: (
    params?: {
      base?: z.RawCreateParams;
      amount?: z.ZodNumber;
      frequency?: z.ZodString;
    },
    init: (
      amountFrequencySchema: AmountFrequencySchema
    ) => AmountFrequencySchema | z.ZodOptional<AmountFrequencySchema> | PartialAmountFrequencySchema = (
      amountFrequencySchema
    ) => amountFrequencySchema
  ) => createUniqueFieldSchema(init(createAmountFrequencySchema(params)), 'amountFrequency'),

  // -------------------- Typeahead Fields --------------------
  /**
   * ABN field
   */
  BusinessLookupField: (
    params?: z.RawCreateParams,
    init: (
      businessLookupSchema: BusinessLookupFieldSchema
    ) => BusinessLookupFieldSchema | z.ZodOptional<BusinessLookupFieldSchema> = (businessLookupSchema) =>
      businessLookupSchema
  ) => createUniqueFieldSchema(init(createBusinessLookupFieldItemSchema(params)), 'abn'),
  /**
   * Industry field
   */
  IndustryField: (
    params?: z.RawCreateParams,
    init: (industrySchema: IndustryFieldSchema) => IndustryFieldSchema | z.ZodOptional<IndustryFieldSchema> = (
      industrySchema
    ) => industrySchema
  ) => createUniqueFieldSchema(init(createIndustryFieldItemSchema(params)), 'industry'),
  /**
   * Address field
   */
  AddressField: (
    params?: z.RawCreateParams,
    init: (addressSchema: AddressFieldSchema) => AddressFieldSchema | z.ZodOptional<AddressFieldSchema> = (
      addressSchema
    ) => addressSchema
  ) => createUniqueFieldSchema(init(createAddressFieldItemSchema(params)), 'address'),
  /**
   * Occupation field
   */
  OccupationField: (
    params?: z.RawCreateParams,
    init: (occupationSchema: OccupationFieldSchema) => OccupationFieldSchema | z.ZodOptional<OccupationFieldSchema> = (
      occupationSchema
    ) => occupationSchema
  ) => createUniqueFieldSchema(init(createOccupationFieldItemSchema(params)), 'occupation'),
  /**
   * Financial Institution field
   */
  InstitutionField: (
    params?: z.RawCreateParams,
    init: (
      institutionSchema: InstitutionFieldSchema
    ) => InstitutionFieldSchema | z.ZodOptional<InstitutionFieldSchema> = (institutionSchema) => institutionSchema
  ) => createUniqueFieldSchema(init(createInstitutionFieldItemSchema(params)), 'institution'),
  /**
   * Payees field
   *
   * It's expected that TypeaheadField's typing will set externally
   */
  PayeesField: (params?: z.RawCreateParams) =>
    createUniqueFieldSchema(
      z.object(
        {},
        params ?? {
          required_error: 'Please select a payee',
        }
      ),
      'payees'
    ),

  // ----------------------------------------
};

// Only used for mappings of zod type to component.
// react-ts-form will map branded types to specific components, regardless of any additional conditionals, e.g. .min(), .regex()
const DefaultFormField = {
  Text: FormField.Text(),
  TextArea: FormField.TextArea(),
  Number: FormField.Number(),
  Password: FormField.Password(),
  PasswordCode: FormField.PasswordCode(),
  BooleanCheckbox: FormField.BooleanCheckbox(),
  Currency: FormField.Currency(),
  Date: FormField.Date(),
  Select: FormField.Select(),
  SelectRadio: FormField.SelectRadio(),
  Switch: FormField.Switch(),
  ToggleGroupBoolean: FormField.ToggleGroupBoolean(),
  ToggleGroupSingle: FormField.ToggleGroupSingle(),
  ToggleGroupMulti: FormField.ToggleGroupMulti(),
  File: FormField.File(),
  MultiCheckbox: FormField.MultiCheckbox(),
  MultiCheckboxRoleField: FormField.MultiCheckboxRoleField(),
  AustralianPhoneNumber: FormField.AustralianPhoneNumber(),
  AustralianMobileNumber: FormField.AustralianMobileNumber(),
  AmountFrequencyField: FormField.AmountFrequencyField(),
  // Typeahead Fields
  BusinessLookupField: FormField.BusinessLookupField(),
  IndustryField: FormField.IndustryField(),
  PayeesField: FormField.PayeesField(),
  AddressField: FormField.AddressField(),
  OccupationField: FormField.OccupationField(),
  InstitutionField: FormField.InstitutionField(),
} as const;

export const mapping = [
  [DefaultFormField.Text, TextField] as const,
  [DefaultFormField.TextArea, TextAreaField] as const,
  [DefaultFormField.Number, NumberField] as const,
  [DefaultFormField.Password, PasswordField] as const,
  [DefaultFormField.PasswordCode, PasswordCodeField] as const,
  [DefaultFormField.BooleanCheckbox, BooleanCheckboxField] as const,
  [DefaultFormField.Currency, CurrencyField] as const,
  [DefaultFormField.Date, DateField] as const,
  [DefaultFormField.Select, SelectField] as const,
  [DefaultFormField.SelectRadio, SelectRadioField] as const,
  [DefaultFormField.Switch, SwitchField] as const,
  [DefaultFormField.ToggleGroupBoolean, ToggleGroupBooleanField] as const,
  [DefaultFormField.ToggleGroupSingle, ToggleGroupSingleField] as const,
  [DefaultFormField.ToggleGroupMulti, ToggleGroupMultiField] as const,
  [DefaultFormField.File, FileUploadField] as const,
  [DefaultFormField.MultiCheckbox, MultiCheckboxField] as const,
  [DefaultFormField.MultiCheckboxRoleField, MultiCheckboxRoleField] as const,
  [DefaultFormField.AustralianPhoneNumber, AustralianPhoneNumberField] as const,
  [DefaultFormField.AustralianMobileNumber, AustralianPhoneNumberField] as const, // intentionally reusing same component as AustralianPhoneNumber
  [DefaultFormField.AmountFrequencyField, AmountFrequencyField] as const,
  // Typeahead Fields
  [DefaultFormField.BusinessLookupField, BusinessLookupField] as const,
  [DefaultFormField.IndustryField, IndustryField] as const,
  [DefaultFormField.PayeesField, PayeesField] as const,
  [DefaultFormField.AddressField, AddressField] as const,
  [DefaultFormField.OccupationField, OccupationField] as const,
  [DefaultFormField.InstitutionField, InstitutionField] as const,
] as const;
