import type { ImageImport, ImageSrc } from '../components';

export function getImageSrc<T extends ImageImport>(image: T): ImageSrc {
  if (typeof image === 'number') {
    // png imported as numbers in native
    return image;
  } else if (typeof image === 'function') {
    // svg imported as functions in native
    return image;
  } else if (typeof image === 'object') {
    // all images imported as objects in web
    return image.src;
  }

  throw new Error('Invalid image type.');
}
