'use client';

import type { GetProps, StackProps } from '@tamagui/core';
import { getTokenValue, isWeb, styled } from '@tamagui/core';
import { useFocusable } from '@tamagui/focusable';
import { TextInput } from 'react-native';
import { YStack } from 'tamagui';
import type { ReactElement } from 'react';
import { cloneElement, isValidElement, useState } from 'react';
import { AlertCircle, Check, Lock } from '@tamagui/lucide-icons';
import type { IconProps } from '@tamagui/helpers-icon';
import { Label, type LabelProps } from '../Label';
import { Text } from '../Text';
import { shadowOpacity, shadowRadius } from '../../tokens/shadow';
import {
  iconSize,
  floatingLabelPaddingLeft,
  floatingLabelPaddingTop,
  inputLabelPaddingLeft,
  inputLabelPaddingTop,
} from './inputStyleAdjustmentFunctions';

/* -------------------------------------------------------------------------------------------------
 * Base Input Component
 * - Optional inline Label
 * - Optional start and end icons
 * -----------------------------------------------------------------------------------------------*/

export const getInputBaseVariantTokens = (size: '$sm' | '$md' | '$lg' | '$xl') => {
  switch (size) {
    case '$sm':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-sm',
        paddingVertical: '$space.input/space/vertical-padding-sm',
        height: '$size.input/size/sm',
        fontSize: '$sm',
        fontWeight: '$sm',
        lineHeight: '$sm',
        letterSpacing: '$sm',
      } as const;
    case '$md':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-md',
        paddingVertical: '$space.input/space/vertical-padding-md',
        height: '$size.input/size/md',
        fontSize: '$sm',
        fontWeight: '$sm',
        lineHeight: '$sm',
        letterSpacing: '$sm',
      } as const;
    case '$lg':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-lg',
        paddingVertical: '$space.input/space/vertical-padding-lg',
        height: '$size.input/size/lg',
        fontSize: '$md',
        fontWeight: '$md',
        lineHeight: '$md',
        letterSpacing: '$md',
      } as const;
    case '$xl':
      return {
        paddingHorizontal: '$space.input/space/horizontal-padding-xl',
        paddingVertical: '$space.input/space/vertical-padding-xl',
        height: '$size.input/size/xl',
        fontSize: '$md',
        fontWeight: '$md',
        lineHeight: '$md',
        letterSpacing: '$md',
      } as const;
  }
};

export const InputBaseFrame = styled(TextInput, {
  name: 'InputBase',
  fontFamily: '$bodyEmphasised',
  borderRadius: '$radius.form/radius/formcontrol',
  color: '$form/color/form-fg-default',
  backgroundColor: '$background/transparent',
  borderColor: '$form/color/form-border-default',
  minWidth: 0,
  borderWidth: '$size.input/size/border-width',
  placeholderTextColor: '$form/color/form-fg-subdued',
  hoverStyle: {},
  focusStyle: {
    borderColor: '$form/color/form-border-selected',
    shadowColor: '$form/color/form-border-selected',
    shadowOpacity: shadowOpacity.on,
    shadowRadius: shadowRadius.on,
    outlineColor: '$form/color/form-border-selected',
    outlineWidth: '$size.input/size/border-focus-width',
    outlineStyle: 'solid',
    outlineOffset: -getTokenValue('$size.input/size/border-width'), // borderWidth is still 1 for input text spacing. Offsetting so the outline overlaps the border.
  },
  ...(isWeb
    ? {
        tabIndex: 0,
      }
    : {
        focusable: true,
      }),
  variants: {
    size: {
      $sm: getInputBaseVariantTokens('$sm'),
      $md: getInputBaseVariantTokens('$md'),
      $lg: getInputBaseVariantTokens('$lg'),
      $xl: getInputBaseVariantTokens('$xl'),
    },
    disabled: {
      true: {
        backgroundColor: '$background/disabled',
        color: '$foreground/disabled',
      },
    },
  } as const,

  defaultVariants: {
    size: '$md',
  },
});

export interface InputBaseFrameExtraProps {
  label?: string;
  labelProps?: Omit<LabelProps, 'ref'>;
  inlineLabel?: boolean;
  fieldLabel?: string;
  startIcon?: ReactElement;
  startIconContainerProps?: StackProps;
  endIcon?: ReactElement;
  endIconContainerProps?: StackProps;
  /**
   * There is a specific bug with Tamagui where nested styled/styleable is dropping disabled along the way.
   * Specifically, any use of styled() on InputBase, that parent component will not pass disabled properly to InputBase, despite what the docs say.
   * Creating a prop which will just pass through this value to disabled
   */
  inputDisabled?: boolean;
  /**
   * Prefix to visually display at the start of the input field.
   *
   * It **will not** transform the input value in any way.
   *
   * If provided, startIcon will be ignored.
   */
  prefix?: string;
}

type InputBaseFrameBaseProps = GetProps<typeof InputBaseFrame>;

export type InputBaseProps = InputBaseFrameBaseProps & InputBaseFrameExtraProps;

export const InputBase = InputBaseFrame.styleable<InputBaseFrameExtraProps>(
  (
    {
      label,
      labelProps,
      inlineLabel = true,
      fieldLabel,
      startIcon,
      startIconContainerProps,
      endIcon,
      endIconContainerProps,
      inputDisabled,
      prefix,
      ...props
    },
    ref
  ) => {
    // Parse input related props, ignore any extra props
    const { placeholder, size = '$md', disabled, value, ...inputProps } = useInputProps(props, ref);
    const [labelHeight, setLabelHeight] = useState<number>(0);
    const [prefixWidth, setPrefixWidth] = useState<number>(0);

    const renderFloatingLabel =
      inlineLabel && ((label && value) || (label && placeholder) || (label && prefix)) && size !== '$sm';

    // Workaround, see inputDisabled description.
    const isDisabled = inputDisabled !== undefined ? inputDisabled : disabled;

    return (
      <YStack space="$lg">
        {fieldLabel ? <Label variant="bodyMediumEm">{fieldLabel}</Label> : null}
        <YStack>
          <InputBaseFrame
            size={size} // This will set padding and height
            disabled={isDisabled}
            // Override size's padding if floating label is present
            {...(renderFloatingLabel
              ? {
                  paddingBottom: floatingLabelPaddingTop(size, false),
                  paddingTop: labelHeight + floatingLabelPaddingTop(size, false),
                }
              : {})}
            paddingLeft={
              prefix
                ? prefixWidth + inputLabelPaddingLeft(size, false) + getTokenValue('$input/space/message-gap')
                : inputLabelPaddingLeft(size, startIcon !== undefined)
            }
            paddingRight={inputLabelPaddingLeft(size, endIcon !== undefined)}
            value={value}
            placeholder={inlineLabel && label && !placeholder && !prefix ? label : placeholder}
            {...inputProps}
          />

          {startIcon && !prefix && isValidElement<IconProps>(startIcon) ? (
            <YStack
              {...startIconContainerProps}
              position="absolute"
              top={inputLabelPaddingTop(size)}
              bottom={inputLabelPaddingTop(size)}
              left={inputLabelPaddingLeft(size, false)}
              alignItems="center"
              justifyContent="center"
            >
              {cloneElement(startIcon, {
                // Set icon size based on input size
                size: iconSize(size),
                // If input is disabled, change the color of the provided icon
                color: isDisabled
                  ? '$form/color/form-fg-subdued'
                  : startIcon.props.color ?? '$form/color/form-fg-default',
              })}
            </YStack>
          ) : null}

          {prefix ? (
            <YStack
              position="absolute"
              // For some reason $md is 1px off, but the other sizes are fine
              top={renderFloatingLabel ? labelHeight + floatingLabelPaddingTop(size, size !== '$md') : 0}
              bottom={renderFloatingLabel ? floatingLabelPaddingTop(size) : 0}
              left={renderFloatingLabel ? floatingLabelPaddingLeft(size, false) : inputLabelPaddingLeft(size, false)}
              alignItems="center"
              justifyContent="center"
              onLayout={(e) => {
                setPrefixWidth(e.nativeEvent.layout.width);
              }}
            >
              <Text
                unstyled
                {...getInputBaseVariantTokens(size)}
                paddingHorizontal={0}
                alignContent={
                  // For some reason is slightly off for $lg and $xl when no floating label
                  !renderFloatingLabel && (size === '$lg' || size === '$xl') ? 'flex-end' : 'center'
                }
                color="$form/color/form-fg-default"
              >
                {prefix}
              </Text>
            </YStack>
          ) : null}

          {endIcon && isValidElement<IconProps>(endIcon) ? (
            <YStack
              {...endIconContainerProps}
              position="absolute"
              top={inputLabelPaddingTop(size)}
              bottom={inputLabelPaddingTop(size)}
              right={inputLabelPaddingLeft(size, false)}
              alignItems="center"
              justifyContent="center"
            >
              {cloneElement(endIcon, {
                // Set icon size based on input size
                size: iconSize(size),
                // If input is disabled, change the color of the provided icon
                color: isDisabled
                  ? '$form/color/form-fg-subdued'
                  : endIcon.props.color ?? '$form/color/form-fg-default',
              })}
            </YStack>
          ) : null}

          {/* Floating label when value is present */}
          {/*
        TODO: add a nice animation for the label, some ideas can be taken from the RNP one
        https://github.com/callstack/react-native-paper/blob/main/src/components/TextInput/TextInput.tsx 
      */}
          {renderFloatingLabel ? (
            <YStack
              position="absolute"
              top={floatingLabelPaddingTop(size)}
              left={floatingLabelPaddingLeft(size, startIcon !== undefined && !prefix)}
            >
              <Label
                {...labelProps}
                onLayout={(e) => {
                  setLabelHeight(e.nativeEvent.layout.height);
                  labelProps?.onLayout?.(e);
                }}
                variant="bodySmall"
                color="$form/color/form-fg-subdued"
                lineHeight="unset"
                selectable={false}
              >
                {label}
              </Label>
            </YStack>
          ) : null}
        </YStack>
      </YStack>
    );
  }
);

type ReturnedInputProps = InputBaseProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this one comes from the original Tamagui component
  ref: any;
  editable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this one comes from the original Tamagui component
  onChangeText: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- this one comes from the original Tamagui component
export function useInputProps(props: InputBaseProps, ref: any): ReturnedInputProps {
  const { onChangeText, ref: combinedRef } = useFocusable({
    props,
    ref,
    isInput: true,
  });

  return {
    ref: combinedRef,
    editable: !props.disabled,
    ...props,
    onChangeText,
  };
}

/* -------------------------------------------------------------------------------------------------
 * Usable Input Component
 * - Defined statuses with icons for each
 * - Render hint and error messages below input
 * -----------------------------------------------------------------------------------------------*/

export type InputStatus = 'default' | 'success' | 'error';

export const InputFrame = styled(InputBase, {
  name: 'Input',
  variants: {
    status: {
      default: {
        borderColor: '$form/color/form-border-default',
      },
      success: {
        borderColor: '$form/color/form-border-default',
      },
      error: {
        borderColor: '$form/color/form-border-danger',
      },
    },
  } as const,

  defaultVariants: {
    status: 'default',
  },
});

interface InputFrameExtraProps {
  hint?: string;
  /**
   * Error message to display. If given, will set the `status` prop as error
   */
  error?: string;
  /**
   * Whether to prioritise rendering the `endIcon` if provided, over any status icons
   */
  prioritiseEndIcon?: boolean;
}

export const Input = InputFrame.styleable<InputFrameExtraProps>((props, ref) => {
  const { status, endIcon, hint, error, prioritiseEndIcon = false, disabled, size, ...rest } = props;

  let computedStatus: InputStatus | undefined = status;

  // If disabled, override status to be 'default'
  if (disabled) {
    computedStatus = 'default';
  } else if (error) {
    // If error message is given, override status to be 'error'
    computedStatus = 'error';
  }

  let statusEndIcon: ReactElement | undefined = endIcon;
  if (!prioritiseEndIcon || !endIcon) {
    if (disabled) {
      statusEndIcon = <Lock color="$foreground/disabled" />;
    } else if (computedStatus === 'success') {
      statusEndIcon = <Check color="$alert/color/success/alert-icon-success" />;
    } else if (computedStatus === 'error') {
      statusEndIcon = <AlertCircle color="$alert/color/danger/alert-icon-danger" />;
    }
  }

  return (
    <YStack gap="$space.input/space/message-gap">
      <InputFrame
        ref={ref}
        status={computedStatus}
        endIcon={statusEndIcon}
        size={size}
        inputDisabled={disabled}
        disabled={disabled}
        {...rest}
      />
      {error ? (
        <Text variant="bodySmall" color="$form/color/form-fg-danger" selectable={false}>
          {error}
        </Text>
      ) : null}
      {hint && !error ? (
        <Text variant="bodySmall" color="$form/color/form-fg-subdued" selectable={false}>
          {hint}
        </Text>
      ) : null}
    </YStack>
  );
});

export type InputProps = GetProps<typeof Input>;
